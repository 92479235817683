<template>
  <div>
    <div class="box-shadow bg-white">
      <div class="d-flex-center notice-bar p-20 mb-30">
        <div v-for="(item, index) in noticeList" :key="index">
          <el-link
            type="primary"
            :title="item.title"
            class="link"
            @click="$nav.push('/notice/details/' + item.id)"
            >{{ item.title }}</el-link
          >
          <span class="mx-20">/</span>
        </div>
        <div>
          <el-link type="primary" class="link" @click="$nav.push('/notice')">{{
            $t("n_more")
          }}</el-link>
        </div>
      </div>
    </div>
    <el-card class="content-box">
      <div class="top-info py-20">
        <div class="left-textcontent">
          <h1 class="mr-auto pair-coin my-0" style="margin-bottom: 10px">
            <!-- mb-10 -->
            {{ $route.params.pair.toUpperCase() }} /
            {{ $route.params.coin.toUpperCase() }}
          </h1>
          <div>
            <!-- 行情要交易跳转其他详情 -->
            <!-- <el-col :span="12"> -->
            <el-button
              style="min-width: 134px"
              type="success"
              native-type="button"
              @click="
                $nav.push(
                  '/trading/' + $route.params.pair + '/' + $route.params.coin
                )
              "
              >{{ $t("onJump1") }}</el-button
            >
            <!-- </el-col> -->
            <!-- <el-col :span="12"> -->
            <el-button
              style="min-width: 134px"
              type="success"
              native-type="button"
              @click="
                $nav.push(
                  '/contact/' + $route.params.pair + '/' + $route.params.coin
                )
              "
              >{{ $t("onJump2") }}</el-button
            >
            <!-- </el-col> -->

            <!-- <el-col :span="12">
              <div class="">
                <el-button
                  type="success"
                  icon="el-icon-top"
                  class="w-100"
                  native-type="button"
                  @click="onOrderShow(0)"
                  >{{$t("buyUp")}}</el-button
                >
              </div>
            </el-col>
            <el-col :span="12">
              <div class="">
                <el-button
                  type="danger"
                  icon="el-icon-bottom"
                  class="w-100"
                  native-type="button"
                  @click="onOrderShow(1)"
                  >{{$t("buyDown")}}</el-button
                >
              </div>
            </el-col> -->
            <el-dialog
              :title="$t('Confirm_order.title')"
              width="500px"
              top="4vh"
              :visible.sync="orderShow"
              :close-on-click-modal="false"
            >
              <el-row :gutter="20" type="flex" class="text-center">
                <el-col :span="24">
                  <p class="mt-0 mb-10">{{ $t("Confirm_order.h") }}</p>
                  <h1
                    v-if="orderFormData.type"
                    class="m-0 color-d"
                    v-text="info.lasest_price"
                  />
                  <h1 v-else class="m-0 color-s" v-text="info.lasest_price" />
                </el-col>
              </el-row>
              <el-divider />
              <div v-if="orderFormData.id">
                <div class="text-center my-30">
                  <el-progress
                    v-if="orderFormData.state == 0"
                    type="circle"
                    :percentage="currentRate"
                    :stroke-width="12"
                    :format="formatOrderProgress"
                  />
                  <h1
                    v-else-if="orderFormData.state == 1"
                    class="my-40 font-32"
                  >
                    +{{ Number(orderFormData.total) }} USDT
                  </h1>
                  <h1 v-else class="my-40 font-32">
                    -{{ Number(orderFormData.amount) }} USDT
                  </h1>
                </div>
                <el-divider />
                <el-row :gutter="20" type="flex" class="text-center">
                  <el-col :span="8">
                    <p class="mt-0 mb-10">{{ $t("Confirm_order.Due_time") }}</p>
                    <h3 class="m-0">{{ orderFormData.due_time }}s</h3>
                  </el-col>
                  <el-col :span="8">
                    <p class="mt-0">{{ $t("Confirm_order.Profit") }}</p>
                    <h3 class="m-0">
                      {{ dueTimeList[orderFormData.due_time] }}%
                    </h3>
                  </el-col>
                  <el-col :span="8">
                    <p class="mt-0">{{ $t("middle_window.Amount") }}</p>
                    <h3 class="m-0">{{ orderFormData.amount }} USDT</h3>
                  </el-col>
                </el-row>
                <el-divider />
                <div class="text-center">
                  <el-button
                    type="info"
                    icon="el-icon-close"
                    @click="onOrderHide()"
                    >Close</el-button
                  >
                  <el-button
                    type="warning"
                    icon="el-icon-back"
                    @click="onOrderShow()"
                    >Buy Again</el-button
                  >
                </div>
              </div>
              <el-form
                v-else
                ref="orderForm"
                v-loading="orderLoading"
                element-loading-background="#ffffff88"
                class="white-form"
                :model="orderFormData"
                label-position="top"
                :rules="rules"
              >
                <el-form-item label="Due time (seconds)" class="mb-0">
                  <el-radio-group
                    v-model="orderFormData.due_time"
                    size="large"
                    class="radio-group"
                  >
                    <el-radio :label="30" border>30s (Profit:75%)</el-radio>
                    <el-radio :label="60" border>60s (Profit:80%)</el-radio>
                    <el-radio :label="90" border>90s (Profit:85%)</el-radio>
                    <el-radio :label="180" border>180s (Profit:90%)</el-radio>
                    <el-radio :label="300" border>300s (Profit:95%)</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item :label="$t('Confirm_order.label1')">
                  <el-input
                    v-model="orderFormData.amount"
                    size="large"
                    type="number"
                    prefix-icon="el-icon-money"
                    autocomplete="off"
                    :placeholder="$t('Confirm_order.plc1')"
                    @mousewheel.native.prevent
                  >
                    <template slot="append">USDT</template>
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-radio-group
                    v-model="orderFormData.amount"
                    size="small"
                    class="d-flex-sb"
                  >
                    <el-radio-button label="10">10</el-radio-button>
                    <el-radio-button label="100">100</el-radio-button>
                    <el-radio-button label="200">200</el-radio-button>
                    <el-radio-button label="500">500</el-radio-button>
                    <el-radio-button label="1000">1000</el-radio-button>
                    <el-radio-button label="2000">2000</el-radio-button>
                    <el-radio-button label="5000">5000</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <!-- <div class="font-14 text-center mb-10">
                  <span>USDT balance : </span>
                  <span v-text="userInfo.usdt" />
                  <span class="ml-50">Fee : </span>
                  <span>2%</span>
                </div> -->
                <div class="font-14 text-center mb-20">
                  <span>{{ $t("Confirm_order.Expected_profits") }} : </span>
                  <span
                    class="color-danger font-bold"
                    v-text="expectedProfits"
                  />
                  <span> USDT</span>
                  <span class="ml-20"
                    >{{ $t("Confirm_order.Guaranteed_amount") }} :
                  </span>
                  <span class="color-danger">0</span>
                  <span> USDT</span>
                </div>
                <div class="text-center">
                  <el-button
                    type="info"
                    icon="el-icon-close"
                    @click="onOrderHide()"
                    >Cancel</el-button
                  >
                  <el-button
                    type="primary"
                    icon="el-icon-s-promotion"
                    @click="submitOrderForm()"
                    >Submit</el-button
                  >
                </div>
              </el-form>
            </el-dialog>
          </div>
        </div>

        <div class="right-textcontent">
          <div class="price">
            <div
              class="font-24 price-value"
              :class="{
                'color-s': info.change > 0,
                'color-d': info.change < 0,
              }"
            >
              {{ info.lasest_price }}
            </div>
            <div class="mt-5 color-6 font-14">= {{ info.usd }} (USD)</div>
          </div>
          <div class="change text-center">
            <div v-if="info.change > 0" class="font-20 color-s">
              +{{ info.change }}%
            </div>
            <div v-else-if="info.change == 0" class="font-20">
              {{ info.change }}%
            </div>
            <div v-else class="font-20 color-d">{{ info.change }}%</div>
            <div class="mt-5 color-6 font-14">24H {{ $t("home.change") }}</div>
          </div>
          <div class="high text-center">
            <div class="font-20">{{ info.high }}</div>
            <div class="mt-5 color-6 font-14">
              24H {{ $t("home.high") }} ({{ $route.params.coin }})
            </div>
          </div>
          <div class="low text-center">
            <div class="font-20">{{ info.low }}</div>
            <div class="mt-5 color-6 font-14">
              24H {{ $t("home.low") }} ({{ $route.params.coin }})
            </div>
          </div>
          <!-- <div class="finished text-center">
            <div class="font-20">
              {{ info.volume_24h }}
            </div>
            <div class="mt-5 color-6 font-14">
              24H {{ $t("home.finished") }} ({{ $route.params.pair }})
            </div>
          </div> -->
        </div>
      </div>
      <div class="kline-box mt-20">
        <div class="kline-tools font-bold">
          <!-- k线面积图 -->
          <el-button
            :type="Period == 4 && drawType ? 'success' : 'primary'"
            size="mini"
            plain
            class="bg-tw-50"
            @click="ChangePeriod(4, true)"
            >Time</el-button
          >

          <el-button
            :type="Period == 4 && !drawType ? 'success' : 'primary'"
            size="mini"
            plain
            class="bg-tw-50"
            @click="ChangePeriod(4, false)"
            >1Min</el-button
          >
          <el-button
            :type="Period == 5 ? 'success' : 'primary'"
            size="mini"
            plain
            class="bg-tw-50"
            @click="ChangePeriod(5)"
            >5Min</el-button
          >
          <el-button
            :type="Period == 6 ? 'success' : 'primary'"
            size="mini"
            plain
            class="bg-tw-50"
            @click="ChangePeriod(6)"
            >15Min</el-button
          >
          <el-button
            :type="Period == 7 ? 'success' : 'primary'"
            size="mini"
            plain
            class="bg-tw-50"
            @click="ChangePeriod(7)"
            >30Min</el-button
          >
          <el-button
            :type="Period == 0 ? 'success' : 'primary'"
            size="mini"
            plain
            class="bg-tw-50"
            @click="ChangePeriod(0)"
            >1Day</el-button
          >
        </div>
        <div
          class="kline-view"
          ref="kline"
          style="height: 500px; width: 1160px; position：relative"
        />
      </div>
    </el-card>
    <div class="content-box mb-50 mt-20">
      <el-row :gutter="20">
        <el-col :span="18">
          <div class="bg-white box-shadow border-r">
            <!-- 新增完整列表 -->
            <!-- <div class="content-box bg-white box-shadow mb-30"> -->
            <div class="tabs">
              <div
                class="tab px-25 pt-20"
                :class="{ active: marketCoin == 'USDT' }"
                @click="onTab('USDT')"
              >
                USDT {{ $t("look") }}
              </div>
              <!-- <div
                class="tab px-25 pt-20"
                :class="{ active: marketCoin == 'BTC' }"
                @click="onTab('BTC')"
              >
                BTC {{ $t("look") }}
              </div>
              <div
                class="tab px-25 pt-20"
                :class="{ active: marketCoin == 'ETH' }"
                @click="onTab('ETH')"
              >
                ETH {{ $t("look") }}
              </div> -->
            </div>
            <el-row
              :gutter="20"
              type="flex"
              justify="center"
              align="middle"
              class="font-14 color-gray px-20 py-15"
            >
              <el-col :span="4">{{ $t("home.pair") }}</el-col>
              <el-col :span="4">{{ $t("home.price") }}</el-col>
              <el-col :span="4">{{ $t("home.change") }}</el-col>
              <el-col :span="4">24H {{ $t("home.high") }}</el-col>
              <el-col :span="4">24H {{ $t("home.low") }}</el-col>
              <!-- <el-col :span="4">24H {{ $t("home.finished") }}</el-col> -->
              <el-col class="text-right" :span="3">{{
                $t("home.deal")
              }}</el-col>
            </el-row>
            <el-row
              v-for="(item, index) in market"
              :key="index"
              :gutter="20"
              type="flex"
              justify="center"
              align="middle"
              class="market-body px-20 py-15 border-top"
            >
              <el-col :span="1">
                <div
                  v-if="item.logo"
                  class="icon icon-style-yuan icon-stars"
                  :style="{ backgroundImage: 'url(' + item.logo + ')' }"
                />
                <div
                  v-else
                  class="icon icon-style-yuan icon-stars"
                  :class="'icon-coin-' + item.pair.toLowerCase()"
                />
              </el-col>
              <el-col :span="3"
                ><span
                  class="is-link color-p-h"
                  style="font-size: 13px"
                  @click="$nav.push('/market/' + item.pair + '/' + item.coin)"
                  >{{ item.pair.toUpperCase() }} /
                  {{ item.coin.toUpperCase() }}</span
                ></el-col
              >
              <el-col :span="4">{{ item.lasest_price }}</el-col>
              <el-col :span="4" class="d-flex-align-center">
                <el-tag
                  v-if="item.change > 0"
                  class="change"
                  type="success"
                  effect="dark"
                  size="medium"
                  >+{{ item.change }}%</el-tag
                >
                <el-tag
                  v-else-if="item.change == 0"
                  class="change"
                  type="warning"
                  effect="dark"
                  size="medium"
                  >0.00%</el-tag
                >
                <el-tag
                  v-else
                  class="change"
                  type="danger"
                  effect="dark"
                  size="medium"
                  >{{ item.change }}%</el-tag
                >
                <i
                  class="el-icon-top ml-5"
                  :class="{ 'd-none': !item.showUp }"
                />
                <i
                  class="el-icon-bottom ml-5"
                  :class="{ 'd-none': !item.showDown }"
                />
              </el-col>
              <el-col :span="4">{{ item.high }}</el-col>
              <el-col :span="4">{{ item.low }}</el-col>
              <!-- <el-col :span="4">{{ item.volume_24h }}</el-col> -->
              <el-col class="deal text-right" :span="3">
                <el-button
                  type="primary"
                  size="small"
                  plain
                  class="bg-t"
                  icon="el-icon-s-data"
                  @click="$nav.push('/market/' + item.pair + '/' + item.coin)"
                  >{{ $t("look") }}</el-button
                >
              </el-col>
            </el-row>
            <div
              v-if="market.length < 1"
              v-loading="true"
              element-loading-background="#00000000"
              class="p-50"
            />
            <!-- </div> -->
            <!-- 新增完整列表 -end -->
          </div>
          <!-- <div class="bg-white box-shadow border-r">
            <div class="tabs d-flex-center border-bottom font-14">
              <div
                class="tab px-25 pt-20 flex-1 mr-auto"
                :class="{ active: marketCoin == 'USDT' }"
                @click="onTab('USDT')"
              >
                USDT
              </div>
              <div
                class="tab px-25 pt-20 flex-1"
                :class="{ active: marketCoin == 'BTC' }"
                @click="onTab('BTC')"
              >
                BTC
              </div>
              <div
                class="tab px-25 pt-20 flex-1"
                :class="{ active: marketCoin == 'ETH' }"
                @click="onTab('ETH')"
              >
                ETH
              </div>
            </div>
            <el-row
              :gutter="10"
              type="flex"
              justify="center"
              align="middle"
              class="color-gray px-20 py-15 font-12"
            >
              <el-col :span="8">Pair</el-col>
              <el-col :span="10">Price</el-col>
              <el-col class="text-right" :span="6">Change</el-col>
            </el-row>
            <div
              v-for="(item, index) in market"
              :key="index"
              class="is-link"
              @click="$nav.replace('/market/' + item.pair + '/' + item.coin)"
            >
              <el-row
                :gutter="10"
                type="flex"
                justify="center"
                align="middle"
                class="market-body px-20 py-15"
              >
                <el-col :span="3">
                  <i
                    v-if="item.logo"
                    class="icon icon-stars"
                    :style="{ backgroundImage: 'url(' + item.logo + ')' }"
                  />
                  <i
                    v-else
                    class="icon icon-stars"
                    :class="'icon-coin-' + item.pair.toLowerCase()"
                  />
                </el-col>
                <el-col :span="5" class="font-14">{{
                  item.pair.toUpperCase()
                }}</el-col>
                <el-col :span="10" class="font-14">{{
                  item.lasest_price
                }}</el-col>
                <el-col :span="6" class="text-right">
                  <el-tag
                    v-if="item.change > 0"
                    class="change"
                    type="success"
                    effect="dark"
                    size="small"
                    >+{{ item.change.toFixed(2) }}%</el-tag
                  >
                  <el-tag
                    v-else-if="item.change == 0"
                    class="change"
                    type="warning"
                    effect="dark"
                    size="small"
                    >0.00%</el-tag
                  >
                  <el-tag
                    v-else
                    class="change"
                    type="danger"
                    effect="dark"
                    size="small"
                    >{{ item.change.toFixed(2) }}%</el-tag
                  >
                </el-col>
              </el-row>
            </div>
          </div> -->
        </el-col>

        <el-col :span="6">
          <div class="bg-white box-shadow border-r">
            <el-row
              :gutter="20"
              type="flex"
              justify="center"
              align="middle"
              class="border-bottom px-20 py-15 font-12"
            >
              <el-col :span="6">{{ $t("middle_window.Position") }}</el-col>
              <el-col :span="9">{{ $t("middle_window.Price") }}</el-col>
              <el-col class="text-right" :span="9">{{
                $t("middle_window.Amount")
              }}</el-col>
            </el-row>
            <div v-if="mbp.asks.length == 5" class="mbp-sell color-s">
              <div
                v-for="index in [4, 3, 2, 1, 0]"
                :key="index"
                @click="onSetPrice(mbp.asks[index][0])"
                class="is-link"
              >
                <el-row
                  :gutter="20"
                  type="flex"
                  justify="center"
                  align="middle"
                  class="border-bottom font-12 px-20 py-10"
                >
                  <el-col :span="6" class="font-14">Sell{{ index + 1 }}</el-col>
                  <el-col :span="9" class="font-14">{{
                    mbp.asks[index][0]
                  }}</el-col>
                  <el-col :span="9" class="text-right">{{
                    Number(mbp.asks[index][1]).toFixed(8) * 1
                  }}</el-col>
                </el-row>
              </div>
            </div>
            <div v-if="mbp.bids.length == 5" class="mbp-buy color-d">
              <div
                v-for="index in [0, 1, 2, 3, 4]"
                :key="index"
                @click="onSetPrice(mbp.bids[index][0])"
                class="is-link"
              >
                <el-row
                  :gutter="20"
                  type="flex"
                  justify="center"
                  align="middle"
                  class="border-bottom font-12 px-20 py-10"
                >
                  <el-col :span="6" class="font-14">Buy{{ index + 1 }}</el-col>
                  <el-col :span="9" class="font-14">{{
                    mbp.bids[index][0]
                  }}</el-col>
                  <el-col :span="9" class="text-right">{{
                    Number(mbp.bids[index][1]).toFixed(8) * 1
                  }}</el-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import coinLogo from "@/assets/coin/logo";
import HQChart from "hqchart";
HQChart.Chart.JSConsole.Chart.Log = () => {};
HQChart.Chart.JSConsole.Complier.Log = () => {};
import request from "@/services/axios";
//黑色风格
function GetDevicePixelRatio() {
  return window.devicePixelRatio || 1;
}
const BLACK_STYLE = {
  BGColor: "rgb(23,26,37)", //背景色
  TooltipBGColor: "rgb(255, 255, 255)", //背景色
  TooltipAlpha: 0.92, //透明度

  SelectRectBGColor: "rgba(1,130,212,0.06)", //背景色
  //  SelectRectAlpha: 0.06;                  //透明度

  //K线颜色
  UpBarColor: "rgb(25,158,0)", //上涨
  DownBarColor: "rgb(238,21,21)", //下跌
  UnchagneBarColor: "rgb(228,228,228)", //平盘

  Minute: {
    VolBarColor: "rgb(255,236,0)", //分时图成交量柱子颜色（已经不用了 使用红绿柱)
    PriceColor: "rgb(25,180,231)", //分时图价格线颜色
    AreaPriceColor: "rgba(63,158,255,.3)", //价格的面积图
    AvPriceColor: "rgb(255,236,0)", //分时图均价线颜色
    PositionColor: "rgb(218,165,32)", //持仓量线段颜色
  },

  DefaultTextColor: "rgb(255,255,255)",
  DefaultTextFont: 14 * GetDevicePixelRatio() + "px 微软雅黑",
  TitleFont: 13 * GetDevicePixelRatio() + "px 微软雅黑", //标题字体(动态标题 K线及指标的动态信息字体)

  //K线信息文字
  DownTextColor: "rgb(238,21,21)", //下跌
  UpTextColor: "rgb(25,158,0)", //上涨
  UnchagneTextColor: "rgb(101,104,112)", //平盘
  CloseLineColor: "rgb(178,34,34)", //收盘价线

  FrameBorderPen: "rgba(236,236,236,0.13)", //边框
  FrameSplitPen: "rgba(236,236,236,0.13)", //分割线
  FrameSplitTextColor: "rgb(101,104,112)", //刻度文字颜色
  FrameSplitTextFont: 12 * GetDevicePixelRatio() + "px 微软雅黑", //坐标刻度文字字体
  FrameTitleBGColor: "rgb(31,38,54)", //标题栏背景色

  Frame: { XBottomOffset: 1 * GetDevicePixelRatio() }, //X轴文字向下偏移

  CorssCursorBGColor: "rgb(43,54,69)", //十字光标背景
  CorssCursorTextColor: "rgb(255,255,255)",
  CorssCursorTextFont: 12 * GetDevicePixelRatio() + "px 微软雅黑",
  CorssCursorPenColor: "rgb(130,130,130)", //十字光标线段颜色

  KLine: {
    MaxMin: {
      Font: 12 * GetDevicePixelRatio() + "px 微软雅黑",
      Color: "rgb(111,111,111)",
    }, //K线最大最小值显示
    //信息地雷
    Info: {
      Color: "rgb(205,149,12)",
      TextColor: "#afc0da",
      TextBGColor: "#1a283e",
    },
  },

  Index: {
    //指标线段颜色
    LineColor: [
      "rgb(255,189,09)",
      "rgb(22,198,255)",
      "rgb(174,35,161)",
      "rgb(236,105,65)",
      "rgb(68,114,196)",
      "rgb(229,0,79)",
      "rgb(0,128,255)",
      "rgb(252,96,154)",
      "rgb(42,230,215)",
      "rgb(24,71,178)",
    ],
    NotSupport: { Font: "14px 微软雅黑", TextColor: "rgb(52,52,52)" },
  },

  //自定义指标默认颜色
  ColorArray: [
    "rgb(255,174,0)",
    "rgb(25,199,255)",
    "rgb(175,95,162)",
    "rgb(236,105,65)",
    "rgb(68,114,196)",
    "rgb(229,0,79)",
    "rgb(0,128,255)",
    "rgb(252,96,154)",
    "rgb(42,230,215)",
    "rgb(24,71,178)",
  ],

  //画图工具
  DrawPicture: {
    LineColor: "rgb(30,144,255)",
    PointColor: "rgb(105,105,105)",
  },
};
const LIGHT_STYLE = {
  //K线颜色
  UpBarColor: "rgb(25,158,0)", //上涨
  DownBarColor: "rgb(238,21,21)", //下跌
  UnchagneBarColor: "rgb(228,228,228)", //平盘

  //K线信息文字
  DownTextColor: "rgb(238,21,21)", //下跌
  UpTextColor: "rgb(25,158,0)", //上涨
  UnchagneTextColor: "rgb(101,104,112)", //平盘
  CloseLineColor: "rgb(178,34,34)", //收盘价线
};

const STYLE_TYPE_ID = {
  LIGTH_ID: 0, //黑色风格
  BLACK_ID: 1, //黑色风格
};

function HQChartStyle() {}

HQChartStyle.GetStyleConfig = function (
  styleid //获取一个风格的配置变量
) {
  switch (styleid) {
    case STYLE_TYPE_ID.LIGHT_ID:
      return LIGHT_STYLE;
    case STYLE_TYPE_ID.BLACK_ID:
      return BLACK_STYLE;
    default:
      return null;
  }
};
let Period = 0;

export default {
  data() {
    return {
      chart: null,
      drawType: false,
      coinLogo,
      noticeList: [],
      info: {
        // price: "0.0000",
        // usd: "0.00",
        // change: 0,
        // accuracy: 2,
        // high: "0.00",
        // low: "0.00",
        // volume: "0.00",
      },
      Period: 0,
      KLine: {
        JSChart: null,
        Option: {
          Type: "历史K线图",
          // Language: this.$store.state.locale == "zh-TW" ? "TC" : "EN",
          Language: "EN",
          SplashTitle:
            this.$store.state.locale == "zh-TW" ? "數據加載中" : "Loading",
          // NetworkFilter: NetworkFilter,
          IsShowRightMenu: false,
          IsAutoUpdate: false,
          IsApiPeriod: true,
          IsShowCorssCursorInfo: true,
          CorssCursorInfo: { Left: 2, Right: 2, IsShowCorss: true },
          Windows: [
            // { Index: 'EMPTY'}, //设置一个空的指标 这样就不会显示指标了
            // {
            //   Index: "EMPTY", //设置为空指标
            //   Modify: false,
            //   Change: false,
            //   TitleHeight: 0, //去掉指标信息标题的高度
            // },
            {
              Index: "MA",
              Modify: true,
              Change: true,
            },
            {
              Index: "VOL",
              Modify: true,
              Change: true,
            },
          ],
          Border: {
            Left: 0,
            Right: 0, //右边间距
            Top: 25,
            Bottom: 25,
          },

          // 2023.2.27
          //子框架设置
          Frame: [
            {
              SplitCount: 3,
              SplitType: 1,
              // YCoordinateType:2,
              // SplitCount:8,
              // BorderLine:false,
              // IsShowLeftText: false,
              // IsShowXLine:false,IsShowYLine:false,
              // FrameSplitPen: "red",          //分割线
              // FrameSplitTextColor: "red",     //刻度文字颜色
              // FrameTitleBGColor: "red",      //标题栏背景色
              Height: 8,
              TopSpace: 0,
              BottomSpace: 0,
              IsShowLeftText: false, //不显示左边刻度文字
              IsShowRightText: true, //显示右边刻度文字
              Custom: [
                {
                  Type: 0,
                  Position: "right",
                  // IsShowLine: false,
                  LineType: 1,
                  Data: {
                    TextColor: "black",
                  },
                },
              ],
              Data: {
                TextColor: "black",
              },
            },
            {
              SplitCount: 3,
              SplitType: 1,
              IsShowIndexName: false,
              IsShowLeftText: false,
              IsShowRightText: false,
              Height: 2,
              TopSpace: 0,
              BottomSpace: 0,
              // IsShowXLine: false,
              // IsShowYLine: false
            },
          ],

          KLine: {
            Right: 1, //复权 0 不复权 1 前复权 2 后复权
            Period: 0, //周期: 0 日线 1 周线 2 月线 3 年线
            PageSize: 70,
            IsShowTooltip: false,
          },
          KLineTitle: {
            IsShowName: false,
            IsShowSettingInfo: false,
          },
        },
      },
      mbp: {
        bids: [
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
        ],
        asks: [
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
        ],
      },
      klineLoading: true,
      loading: false,
      dataVersion: 0,
      timer: null,
      // klineSrc: null,
      marketCoin: this.$store.state.marketCoin,
      market: [],
      marketLoading: false,
      marketTimer: null,
      marketDataVersion: 0,
      //
      userInfo: {
        pair: "",
        coin: "",
        usdt: "",
      },
      buyLoading: false,
      buyFormData: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        price: null,
        amount: null,
        pay_password: null,
      },
      buyFormData1: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        price: null,
        amount: null,
        pay_password: null,
      },
      sellLoading: false,
      sellFormData: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        price: null,
        amount: null,
        pay_password: null,
      },
      sellFormData1: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        price: null,
        amount: null,
        pay_password: null,
      },
      tradingLoading: false,
      tradingList: [],
      contactList: [],
      rules: {
        price: [{ required: true, trigger: "change" }],
        amount: [{ required: true, trigger: "change" }],
        pay_password: [{ required: true, trigger: "change" }],
      },
      orderLoading: false,
      orderShow: false,
      rate: 0,
      lastDueTime: 30,
      dueTimer: null,
      dueTimeList: {
        30: 75,
        60: 80,
        90: 85,
        180: 90,
        300: 95,
      },
      orderFormData: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        due_time: 30,
        type: 0,
        amount: "100",
        total: "78",
        id: 1,
        state: 1,
      },

      coinList: ["usdt", "btc", "eth"],

      //新增
      marketLength: 0,
      socket1: null,
      activeName: "first",
      activeName1: "first",
      lever1: 5,
      options1: [
        {
          value: 5,
          label: "5x",
        },
        {
          value: 10,
          label: "10x",
        },
        {
          value: 20,
          label: "20x",
        },
        {
          value: 30,
          label: "30x",
        },
        {
          value: 50,
          label: "50x",
        },
        {
          value: 75,
          label: "75x",
        },
        {
          value: 100,
          label: "100x",
        },
      ],
      userContract: {
        net_worth: "", //净值
        balance: "", //可用余额
        income: "", //持仓收益
        cash_deposit_total: "", //占用保证金
      },
      total1: 0,
      total2: 0,
      pageSize1: 5,
      pageSize2: 5,
      pageNum1: 1,
      pageNum2: 1,
      switch1: true,
      switch2: true,
      labelState: 0,

      Cache: [],

      digit_unit: 0,
    };
  },
  computed: {
    buyTotalValue: function () {
      if (!this.buyFormData.price || !this.buyFormData.amount) return "";
      return (this.buyFormData.price * this.buyFormData.amount).toFixed(8) * 1;
    },
    buyTotalValue1: function () {
      if (!this.buyFormData1.price || !this.buyFormData1.amount) return "";
      // return (this.buyFormData1.price * this.buyFormData1.amount).toFixed(8) * 1;
      //保证金 = (价格 / 杠杆) * 数量
      return this.floatingpoint.mul(
        this.floatingpoint.bed(this.buyFormData1.price, this.lever1),
        this.buyFormData1.amount
      );
    },
    sellTotalValue: function () {
      // if(!this.sellFormData.amount) return '--'
      // return this.sellFormData.amount
      if (!this.sellFormData.price || !this.sellFormData.amount) return "";
      return (
        (this.sellFormData.price * this.sellFormData.amount).toFixed(8) * 1
      );
    },
    sellTotalValue1: function () {
      // if(!this.sellFormData.amount) return '--'
      // return this.sellFormData.amount
      if (!this.sellFormData1.price || !this.sellFormData1.amount) return "";
      // return (
      //   (this.sellFormData1.price * this.sellFormData1.amount).toFixed(8) * 1
      // );
      //保证金 = (价格 / 杠杆) * 数量
      return this.floatingpoint.mul(
        this.floatingpoint.bed(this.sellFormData1.price, this.lever1),
        this.sellFormData1.amount
      );
    },
    expectedProfits: function () {
      if (!this.orderFormData.amount) return "0";
      return (
        // this.orderFormData.amount *
        // (this.dueTimeList[this.orderFormData.due_time] / 100 + 1)
        this.floatingpoint.mul(
          this.orderFormData.amount,
          this.dueTimeList[this.orderFormData.due_time] / 100 + 1
        )
      );
    },
    currentRate() {
      if (this.lastDueTime == 0 || this.orderFormData.due_time == 0) return 0;
      if (this.lastDueTime >= this.orderFormData.due_time) return 100;
      const num =
        (this.lastDueTime / this.orderFormData.due_time).toFixed(2).valueOf() *
        100;
      return num;
    },
  },
  watch: {
    marketCoin: function () {
      this.market = [];
      if (["BTC", "ETH", "USDT"].indexOf(this.marketCoin) < 0) return;
      this.$store.commit("marketCoin", this.marketCoin);
      this.tabChange();
    },
    $route: function () {
      // this.fatchData();

      this.tabChange();

      this.klineLoading = true;
      this.klineSrc =
        "kline.html?symbol=" +
        this.$route.params.pair +
        this.$route.params.coin +
        "&times=86400000";

      this.buyFormData.pair = this.$route.params.pair;
      this.buyFormData.coin = this.$route.params.coin;
      this.buyFormData.price = null;
      this.sellFormData.pair = this.$route.params.pair;
      this.sellFormData.coin = this.$route.params.coin;
      this.sellFormData.price = null;
      this.orderFormData.pair = this.$route.params.pair;
      this.orderFormData.coin = this.$route.params.coin;
      this.orderFormData.amount = "10";
      this.CreateKLineChart();
    },
  },
  created: function () {
    this.fatchNotice();
    // this.klineSrc = 'kline.html?symbol='+this.$route.params.pair+this.$route.params.coin
    // this.fatchData();
    // this.timer = window.setInterval(() => {
    //   if (document.hidden) return;
    //   this.fatchData();
    // }, 900);
    this.tabChange();
    // this.marketTimer = window.setInterval(() => {
    //   if (document.hidden) return;
    //   if (["BTC", "ETH", "USDT"].indexOf(this.marketCoin) < 0) return;
    //   this.tabChange(false);
    // }, 1900);
  },
  beforeDestroy: function () {
    window.clearInterval(this.timer);
    window.clearInterval(this.marketTimer);
    window.clearInterval(this.dueTimer);
  },
  mounted: function () {
    Period = 0;
    this.Period = 0;
    this.CreateKLineChart();
  },
  destroyed: function () {
    this.socket1.close(); //离开路由之后断开websocket连接
  },
  methods: {
    NetworkFilter(data, callback) {
      let that = this;
      //网络协议回调
      // console.log('[NetworkFilter] data', data);
      data.PreventDefault = true; //设置hqchart不請求数据
      if (
        data.Name != "KLineChartContainer::RequestHistoryData" &&
        data.Name != "KLineChartContainer::ReqeustHistoryMinuteData"
      ) {
        return;
      }

      let timeJson = {
        time_4: 60000,
        time_5: 300000,
        time_6: 900000,
        time_7: 1800000,
        time_0: 216000000,
      };

      request
        .post("v1/symbol/getSymbolKine", {
          symbol: this.$route.params.pair + this.$route.params.coin,
          times: timeJson["time_" + this.Period],
        })
        .then((res) => {
          //动态设置默认小数位数
          HQChart.Chart.MARKET_SUFFIX_NAME.GetDefaultDecimal = (symbol) => {
            that.digit_unit = res.data.digit;
            return res.data.digit;
          };
          callback(res.data);
          this.StartWSUpdate(); //加载ws拼接
        })
        .catch((err) => {
          console.error(err);
        });
    },

    //加载ws拼接
    StartWSUpdate() {
      this.socket1.on("symbol_kline", (res) => {
        let $json = JSON.parse(res);
        // console.log($json["5m"])
        if (this.Period == 4) {
          this.OnRecvWSRealtimeData($json["1m"]);
        }
        if (this.Period == 5) {
          this.OnRecvWSRealtimeData($json["5m"]);
        }
        if (this.Period == 6) {
          this.OnRecvWSRealtimeData($json["15m"]);
        }
        if (this.Period == 7) {
          this.OnRecvWSRealtimeData($json["30m"]);
        }
        if (this.Period == 0) {
          this.OnRecvWSRealtimeData($json["1d"]);
        }
      });
    },

    StopWSUpdate() {}, //停止ws更新数据

    // 数据拼接示例
    OnRecvWSRealtimeData(data) {
      // console.log("[KLineChart::OnRecvWSRealtimeData", data);

      if (data) {
        var hqChartData = { code: 0, data: [], ver: 2.0 };
        hqChartData.digit = this.digit_unit;
        hqChartData.symbol = this.$route.params.pair + this.$route.params.coin;
        hqChartData.name = this.$route.params.pair + this.$route.params.coin;
        // data.forEach((item, index) => {
        //   item = Number(item);
        // });
        for (var i in data) {
          data[i] = Number(data[i]);
          // let val1 = data[6]
          // let val2 = data[7]
          // data[6] = val2
          // data[7] = val1
        }
        hqChartData.data.push(data);
        this.chart.JSChartContainer.RecvMinuteRealtimeData(hqChartData);
      }
    },

    CreateKLineChart: function () {
      if (this.KLine.JSChart) {
        this.KLine.JSChart.ChangeSymbol(
          this.$route.params.pair + this.$route.params.coin
        );
        return;
      }
      this.KLine.Option.Symbol =
        this.$route.params.pair + this.$route.params.coin;
      this.KLine.Option.Period = Period;
      this.KLine.Option.KLine.Period = Period;//2023.2.15
      // const black = HQChart.Chart.HQChartStyle.GetStyleConfig(HQChart.Chart.STYLE_TYPE_ID.BLACK_ID)
      const style = HQChartStyle.GetStyleConfig(STYLE_TYPE_ID.LIGHT_ID);
      // HQChart.Chart.JSChart.SetStyle(black)
      HQChart.Chart.JSChart.SetStyle(style);
      this.chart = HQChart.Chart.JSChart.Init(this.$refs.kline);
      this.KLine.Option.NetworkFilter = this.NetworkFilter;
      this.chart.SetOption(this.KLine.Option);
      this.KLine.JSChart = this.chart;
    },
    ChangePeriod: function (period, drawType) {
      Period = period;
      this.Period = period;
      this.drawType = drawType;
      if (this.KLine.JSChart) {
        if (Period == 4 && drawType) {
          // this.drawType = drawType;
          this.KLine.Option.KLine.DrawType = 4;
          //显示为k线面积图的时候去掉指标
          // this.KLine.Option.Windows[0].Change = false
          // this.KLine.Option.Windows[0].Modify = false
          this.KLine.Option.Windows[0].Index = 'EMPTY'
          // this.KLine.Option.Windows[0].TitleHeight = 0
        } else {
          this.KLine.Option.Windows[0].Index = 'MA'
          this.KLine.Option.KLine.DrawType = 0;
        }
        this.KLine.Option.KLine.Period = Period;//2023.2.15
        this.chart.SetOption(this.KLine.Option);
        this.KLine.JSChart = this.chart;
        // this.KLine.JSChart.ChangePeriod(period);
        return;
      }
    },
    // ChangePeriod: function (period) {
    //   Period = period;
    //   this.Period = period;
    //   if (this.KLine.JSChart) {
    //     this.KLine.JSChart.ChangePeriod(period);
    //     return;
    //   }
    // },
    fatchNotice: async function () {
      const { data } = await this.$request.post("v1/notice");
      this.noticeList = data.data;
    },

    onKlineLoad: function () {
      this.klineLoading = false;
    },
    onTab: function (active) {
      this.marketCoin = active;
    },

    tabChange: function () {
      this.marketLoading = true;
      this.$requestNotState
        .post("v1/symbol/getList", { coin: this.marketCoin.toLowerCase() })
        .then(({ data }) => {
          // this.market = data
          this.marketLoading = false;
          let arr = {};
          if (data.list) {
            data.list.forEach(function (item, index) {
              arr[item.pair + "" + item.coin] = item;
            });
          }
          this.info =
            arr[this.$route.params.pair + "" + this.$route.params.coin];
          this.market = arr;
          this.getdata_wss();
        })
        .catch(() => {
          this.marketLoading = false;
        });
    },

    //获取动态数据
    getdata_wss: function () {
      if (this.socket1) {
        this.socket1.close(); //离开路由之后断开websocket连接
      }

      //wss實時請求
      // this.socket1 = this.$io("ws://47.243.105.117:9502/symbol", {
      this.socket1 = this.$io(this.$wsn+"/symbol", {
        query: {},
        transports: ["websocket", "polling"],
        timeout: 5000,
        // pingTimeout: 20000,
        // pingInterval: 25000,
      });

      this.socket1.on("connect_error", (reason) => {
        console.log(reason);
      });

      this.socket1.on("connect", (socket) => {
        // ws连接已建立，此时可以进行socket.io的事件监听或者数据发送操作
        console.log("ws 已连接");

        //全部报价
        this.socket1.emit("symbol_price_list");
        //单一报价
        this.socket1.emit(
          "symbol_price",
          this.$route.params.pair + "" + this.$route.params.coin
        );
        //交易数据
        this.socket1.emit(
          "symbol_mbp",
          this.$route.params.pair + "" + this.$route.params.coin
        );

        // 2023.5.12新增的动态k线数据
        this.socket1.emit(
          "symbol_kline",
          this.$route.params.pair + "" + this.$route.params.coin
        );

        this.socket1.on("symbol_price_list", (res) => {
          this.market[JSON.parse(res).pair + "" + JSON.parse(res).coin]
            ? (this.market[JSON.parse(res).pair + "" + JSON.parse(res).coin] =
                JSON.parse(res))
            : "";
          this.market = JSON.parse(JSON.stringify(this.market));
        });

        this.socket1.on("symbol_price", (res) => {
          this.info = JSON.parse(res);
          // this.info.change = JSON.parse(res).change;
          // this.info.coin = JSON.parse(res).coin;
          // this.info.high = JSON.parse(res).high;
          // this.info.lasest_price = JSON.parse(res).lasest_price;
          // this.info.low = JSON.parse(res).low;
          // this.info.pair = JSON.parse(res).pair;
          // this.info.usd = JSON.parse(res).usd;
          // this.info.volume_24h = JSON.parse(res).volume_24h;
        });

        this.socket1.on("symbol_mbp", (res) => {
          // console.log(JSON.parse(res))
          this.mbp = JSON.parse(res);
        });
      });

      this.socket1.on("disconnect", (reason) => {
        console.log(reason);
      });

      this.socket1.on("error", (msg) => {
        console.log("ws error", msg);
      });
    },

    onSetPrice: function (price) {
      this.buyFormData.price = price;
      this.sellFormData.price = price;
    },

    formatOrderProgress() {
      return `${this.lastDueTime}s`;
    },

    //微合约下单后返回结果接口
    fatchOrderItem: function () {
      if (!this.orderFormData.id) return;
      this.$request
        // .post("contract/item", this.orderFormData)
        .post("v1/time_set/getInfo", this.orderFormData)
        .then(({ data }) => {
          if (data.state == 0) {
            window.setTimeout(() => {
              this.fatchOrderItem;
            }, 500);
          } else {
            this.orderFormData.state = data.state;
          }
        });
    },

    //微合约-下单
    submitOrderForm: async function () {
      try {
        await this.$refs.orderForm.validate();
        this.orderLoading = true;
        // this.orderFormData.amount = Number(this.orderFormData.amount)*100
        const { data } = await this.$request.post(
          "v1/time_set/create",
          // this.orderFormData
          {
            pair: this.orderFormData.pair,
            coin: this.orderFormData.coin,
            amount: this.floatingpoint.mul(this.orderFormData.amount, 100),
            due_time: this.orderFormData.due_time,
            type: this.orderFormData.type,
          }
        );
        this.orderLoading = false;
        this.lastDueTime = this.orderFormData.due_time;
        this.orderFormData.id = data.id;
        this.orderFormData.total = data.total;
        this.dueTimer = window.setInterval(() => {
          if (this.lastDueTime <= 0) {
            window.clearInterval(this.dueTimer);
            this.fatchOrderItem();
            return;
          }
          this.lastDueTime--;
        }, 1000);
      } catch (error) {
        this.orderLoading = false;
      }
    },

    onOrderShow: function (type = -1) {
      window.clearInterval(this.dueTimer);
      this.orderShow = true;
      this.orderFormData.id = 0;
      this.orderFormData.state = 0;
      if (type !== -1) {
        this.orderFormData.type = type;
      }
    },
    onOrderHide: function () {
      this.orderShow = false;
      window.clearInterval(this.dueTimer);
    },
  },
};
</script>
<style>
.white-form input {
  color: black !important;
}
</style>
<style lang="scss" scoped>
.notice-bar {
  .link {
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.top-info {
  .pair-coin {
    font-size: 32px;
  }
}
.kline-box {
  position: relative;
  .kline-tools {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  .kline-view {
    height: 500px;
    border: none;
    width: 1160px;
  }
}
.tabs {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $bgColor;
  .tab {
    padding-bottom: 18px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }
  .active {
    color: $--color-primary;
    border-bottom: 2px solid $--color-primary;
  }
}
.mbp-buy {
  background-color: rgba(255, 59, 59, 0.07);
}
.mbp-sell {
  background-color: rgba(41, 194, 120, 0.07);
}

// 新增样式
.top-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left-textcontent {
  margin-right: 0;
}
.right-textcontent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.right-textcontent > div {
  min-width: 128px;
  padding-left: 20px;
  box-sizing: border-box;
}
.right-textcontent > div:nth-child(2) {
  min-width: 118px;
}
.right-textcontent > div:nth-last-child(1) {
  min-width: 148px;
}
.theme-title {
  font-size: 15px;
  margin: 20px 0 0;
  padding: 0 20px;
  box-sizing: border-box;
}
.theme-title span {
  padding-left: 10px;
  border-left: 3px solid #f9a825;
  color: #333;
}
.top-info .left-textcontent h1 {
  font-size: 34px;
}
.radio-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.radio-group label {
  margin: 0 5px 10px 5px !important;
}
.newstyle {
  padding: 20px;
}
.newstyle-col {
  padding-top: 20px !important;
}

// 顶部信息样式
.top-text {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebedf0;
  box-sizing: border-box;
}
.top-text .top-text-item {
  flex: 1;
  text-align: center;
  padding: 0 10px;
  box-sizing: border-box;
}
.top-text-h {
  font-size: 14px;
  color: #999999;
}
.top-text-p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.in-select {
  width: 120px;
  font-size: 12px;
}
.pagination-box {
  text-align: center;
  padding-top: 10px;
  box-sizing: border-box;
}

// 订单类型
.etab-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid #e3e6e9;
  box-sizing: border-box;
  margin-bottom: 5px;
}
.title-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.title-label-item {
  font-size: 14px;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  line-height: 14px;
}
.title-label-cli {
  width: 1px;
  height: 16px;
  background: #999;
}
.title-label .active {
  color: #f9a825;
}

// 持仓列表

// k线水印
.kline-view {
  background: url(../../assets/index/shui.png) no-repeat 10px 266px;
}
</style>